import linkResolver from './linkResolver'
import useLang from './useLang'

export default (uid, type = 'page') => {
  const { lang } = useLang()
  if (uid === 'kurse') {
    const newLang = lang?.includes('en') ? 'en' : 'de'
    return `https://klingenschmieden.at/${newLang}`
  }
  return linkResolver({ type, uid, lang })
}
