import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { CartProvider } from './src/cart/useCart'
import { ModalProvider } from './src/components/modal'
import { FavsProvider } from './src/favs/useFavs'
import { LangProvider } from './src/utils/useLang'
import axios from 'axios'
import './src/css/tailwind.css'

export const onInitialClientRender = () => {
  axios.defaults.baseURL = process.env.DD_NEW_API
}

const errorHandler = (error) => {
  if ('development' === process.env.GATSBY_ACTIVE_ENV) {
    throw error
  }
}

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary onError={errorHandler}>
    <LangProvider>
      <ModalProvider>
        <CartProvider>
          <FavsProvider>{element}</FavsProvider>
        </CartProvider>
      </ModalProvider>
    </LangProvider>
  </ErrorBoundary>
)
